import axios from "@/plugins/axios";
import { CreateInformation } from "@/@types/information";

class InformationService {
  get(id: string, informationId: string): Promise<any> {
    return axios.get(`/management/facilities/${id}/information/${informationId}`);
  }

  getAll(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}/information`);
  }

  create(id: string, data: CreateInformation): Promise<any> {
    return axios.post(`/management/facilities/${id}/information`, data);
  }

  update(id: string, informationId: string, data: CreateInformation): Promise<any> {
    return axios.put(`/management/facilities/${id}/information/${informationId}`, data);
  }
  delete(id: string, informationId: string): Promise<any> {
    return axios.delete(`/management/facilities/${id}/information/${informationId}`);
  }
}

export default new InformationService();
