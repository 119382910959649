
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import { CreateInformation, InformationData } from "@/@types/information";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import InformationService from "@/services/information";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  name: "NoticeSetting",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
    FormSet,
  },
})
export default class NoticeSetting extends CheckCommonLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }
  private tableLoading = false;
  private noticeSettingHeaders = [
    { text: "お知らせタイトル", value: "title", width: "550px" },
    { text: "公開開始日", value: "start_datetime" },
    { text: "公開終了日", value: "end_datetime" },
    { text: "最終更新日時", value: "updated_at" },
    { text: "", value: "actions", sortable: false, width: "100px" },
  ];
  private showLabel = false;
  private informationList: InformationData[] = [];
  private form: CreateInformation = {
    title: "",
    start_datetime: "",
    end_datetime: "",
    updated_by: this.me?.id,
  };

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async mounted() {
    this.tableLoading = true;
    await this.getInformationList();
    this.tableLoading = false;
  }

  private get dateTimeCheck() {
    const start = moment(this.form.start_datetime);
    const end = moment(this.form.end_datetime);
    return end.isAfter(start);
  }

  private displayDateFactory(date: string, add9?: boolean) {
    if (!add9) {
      return moment(date).format("YYYY/MM/DD");
    } else {
      return moment(date).format("YYYY/MM/DD HH:mm");
    }
  }

  private resetForm() {
    this.form = {
      title: "",
      start_datetime: "",
      end_datetime: "",
      updated_by: "",
    };
  }

  private async getInformationList() {
    const res = await InformationService.getAll(this.hospitalId);
    this.informationList = res.data.data;
    // 降順にソート
    this.informationList.sort(function (x, y) {
      var firstDate = new Date(x.start_datetime),
        SecondDate = new Date(y.start_datetime);

      if (firstDate < SecondDate) return -1;
      if (firstDate > SecondDate) return 1;
      return 0;
    });
  }

  private editInformation(id: string) {
    this.$router.push({ name: "NoticeSettingEdit", params: { id } });
  }

  private async submit() {
    try {
      this.form.start_datetime = moment(this.form.start_datetime)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .toISOString();
      if (this.form.end_datetime) {
        this.form.end_datetime = moment(this.form.end_datetime)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        if (!this.dateTimeCheck) {
          this.showLabel = true;
          setTimeout(() => {
            this.showLabel = false;
          }, 5000);
          return;
        }
      } else {
        delete this.form.end_datetime;
      }
      await InformationService.create(this.hospitalId, this.form);
      location.reload();
    } catch (error: any) {
      this.showModal("saveError");
      throw new Error(error);
    }
  }
}
